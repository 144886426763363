var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auth-page" },
    [
      _c("h2", { staticClass: "text-center" }, [_vm._v("CEDUR 3.0")]),
      _c("nuxt"),
      _c("div", { staticClass: "footer" }, [
        _c("hr"),
        _c(
          "div",
          { staticClass: "text-center mb-3 small" },
          [
            _c(
              "b-link",
              { attrs: { to: { name: "site-notice" }, target: "_blank" } },
              [_vm._v("Impressum & Datenschutz")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }